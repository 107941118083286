import CallToActionBlock from 'libs/wordpress/content/cmsBlocks/CallToActionBlock';
import MaxWidthWrapper from 'components/wrappers/MaxWidthWrapper';
import PropTypes from 'prop-types';
import React from 'react';
import useClientHeight from 'hooks/useClientHeight';
import useHeaderHeights from 'hooks/useHeaderHeights';

const CallToActionModule = ({ isHero = false, ...rest }) => {
    const clientHeight = useClientHeight();
    const headerHeights = useHeaderHeights();

    if (isHero) {
        return (
            <MaxWidthWrapper
                as="section"
                height={[
                    `calc(${clientHeight} - ${headerHeights.banner + headerHeights.mobileAndBreadcrumbsBar}px)`,
                    null,
                    `calc(${clientHeight} - ${headerHeights.banner + headerHeights.tabletAndBreadcrumbsBar}px)`,
                    null,
                    `calc(${clientHeight} - ${headerHeights.banner + headerHeights.desktopAndBreadcrumbsBar}px)`,
                ]}
            >
                <CallToActionBlock isHero {...rest} />
            </MaxWidthWrapper>
        );
    }

    return (
        <MaxWidthWrapper as="section" height={clientHeight}>
            <CallToActionBlock {...rest} />
        </MaxWidthWrapper>
    );
};

CallToActionModule.propTypes = {
    isHero: PropTypes.bool,
};

export default CallToActionModule;
